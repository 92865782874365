$border-radius : 10px;
$main-white: #FFFFFF;
$main-padding: 16px;
$inactive-color: #919298;
$active-bg-color: #F1F5F9;

.main-section-container{
    height: 100vh;
    overflow-y: scroll;
    width: 100%;
}

.main-section-container-mobile{
    overflow-y: scroll;
    width: 100%;
}
.brand-name{
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    margin-left: 5px;
}
.sidebar-container{
    position:relative;
    min-width: 240px;
    display: flex;
}

.sidebar-container-mobile {
    position:relative;
    min-width: 80px;
    display: flex;
    height: 100%;
}

.sidebar{
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    height: 100vh;
    left: 0;
    width: 240px;
    background-color: $main-white; 
    transition: width 2s;
}

.sidebar-mobile{
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    height: 100%;
    left: 0;
    width: 80px;
    background-color: $main-white; 
}

.curr-page{
    font-size: 24px;
    font-weight: bold;
}

.curr-page-subtitle{
    font-size: 15px;
    font-weight: bold;
    color: lightgray;
    position: absolute;
    bottom: 5px;
}

.top-nav{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: inherit;
  padding: 1rem 1rem;
  padding-right: 5rem;
  background-color: $main-white;
  z-index: 10;
  
}

@media screen and (max-width: 768px) {
    .main-section-container {
        padding-top: 2rem;
    }

    .top-nav{
        padding-right: 1rem;
        right: 0;
    }

}


.nav-item.active{
    background-color: $active-bg-color;
    color: black;
    
}

.nav-item, .nav-bottom{
    display: flex;
    flex-direction: row;
    padding: 15px 24px;
    font-size: 14px;
    color: $inactive-color;
    text-decoration: none;
}

.nav-item:hover {
    text-decoration: none;
    color: black;
}

.nav-bottom{
    cursor: pointer;
    margin-top: auto;
    margin-bottom: 1.875rem;
}

.nav-icon{
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.nav-icon-sub{
    width: 18px;
    height: 18px;
    margin-right: 8px;
}

.branding{
    display: flex;
    flex-direction: row;
    padding: 12px 0;
    justify-content: center;
}
.nav-list{
    padding-inline-start: 0;
}

.nav-list, .sub-nav-list{
    list-style: none;
    margin-top: 15px;
    color: #919298;
}



.sub-nav-list{
    margin-top: 0px;
    padding-inline-start: 0;
    .nav-item{
        padding-left: 36px;
        font-size: 12px;
    }
}

.logo-container{
    display: flex;
    align-items: center;
}

.network-logo{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height:32px;
    background-color: #1D4ED8;
    color: white;
    border-radius: 16px;
}

.roundDot{
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    align-self: center;
    margin-left: auto;
}

.roundDot.active{
    background: #34D399;
}
.roundDot.inactive{
    background: #F87171;
}

.network-name{
    font-size: 32px;
    font-weight: bold;
    margin-left: 8px;
}