@import "colors";
@import "sizes";

$box-shadow: 0 2px 4px rgba(0, 0, 0, .03), 0 8px 16px rgba(0, 0, 0, .03);

.image-icon{
    display: inline-block !important;
    margin: 0 3px;
}

.modal-icon{
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block !important;
}

.button-icon{
    width: 14px;
    height: 14px;
}

.btn{
    font-size: $normal-text;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    font-weight: 600;
}

.app-btn-primary{
    background: $primary-blue-500;
    color: white;
    &:hover{
        color: white
    }
    &:disabled{
        background-color: $neutral-gray-200;
    }
}
.app-btn-success{
    background: $success-green-500;
    color: white;
    &:hover{
        color: white
    }
    &:disabled{
        background-color: $neutral-gray-200;
        color: black;
    }
}

.app-btn-secondary{
    background-color: $secondary-yellow-500;
    color: $neutral-gray-900;
}

.app-btn-secondary:disabled{
    background-color: $neutral-gray-200;
}

.app-btn-danger{
    background-color: $error-red-300;
    color: white;
}

.circle-chart-title {
    font-size: 12px;
    font-weight: bold;
    color: #475569;
    font-family: 'Inter';
}

.app-btn-outline {
    outline: none;
    color: $primary-blue-500;
    border: 1px solid $primary-blue-500;
    &:hover{
        color: $primary-blue-500;
        outline: none;
        border: 1px solid $primary-blue-500;
    }
    &:active{
        color: $primary-blue-500;
        outline: none;
        border: 1px solid $primary-blue-500;
    }
    &:focus{
        color: $primary-blue-500;
        outline: none;
        border: 1px solid $primary-blue-500;
    }
}

.app-btn-ghost{
    color: $primary-blue-500;
}

.app-btn-outline-danger{
    outline: 1px solid $error-red-300;
    color: $error-red-300;
    &:hover{
        color: $error-red-300;
    }
}

.btn-extra{
    padding-left: 3rem;
    padding-right: 3rem;
}

.toggle-button{
    padding: .5rem .75rem !important;
    background-color: white !important;
    color: $neutral-gray-900;
    border: none;
    outline: none;
    
    &:hover{
        border: none;
        outline: none;
        color: $neutral-gray-900;
    }
    &:active{
        color: $neutral-gray-900;
    }
    &.active{
        color:$primary-blue-500 !important;
        background-color: $primary-blue-50 !important;
       
    }
}

.show > .btn-primary.dropdown-toggle {
    color: $neutral-gray-900;
    &:focus{
        color: $neutral-gray-900;
    }
}
.btn-check:focus + .btn-primary, .btn-primary:focus{
    color: $neutral-gray-900;
}
.dropdown-toggle::after{
    content: none;
}




// modal section
.modal-head{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modal-container{
    margin: auto;
    background-color: white;
    border-radius: 8px;
    padding: 1.25rem 1rem;
    max-height: 625px;
}

.space-around {
    justify-content: space-around;
}

.btn-48 {
    width: 48%;
}

.modal-icon-container{
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primary-blue-50;
    border-radius: 50%;
}

.modal-title{
    margin: 0;
    margin-top: .75rem;
    color: $neutral-gray-900;
    font-weight: 600;
    text-align: center;
    margin-bottom: .5rem;
    font-size: .75rem;
}

.modal-description{
    color: $neutral-gray-500;
    margin-bottom: .5rem;
}


.modal-md{
    width: 24rem;
}


// form 

.form-group {
    margin-bottom: .5rem;
    label{
    font-size: $small-text;
    color: $neutral-gray-600;
    font-weight: bold;
    margin-bottom: 6px;
    }
    .form-control{
        font-size: $normal-text;
        border-radius: 4px;
        border: 1px solid $neutral-gray-300;
        color: black;

    }
    .form-control::placeholder{
        color: $neutral-gray-50
    }
}

.form-error{
    color: $error-red-300;
    margin-bottom: .5rem;
}

//tooltip

.tooltip-body{
    background-color: $neutral-gray-50;
    border: none !important;
    border-radius: 5px;
    .tooltip-head{
        display: flex;
        align-items: center;
        .tooltip-title{
            margin: 0;
            font-size: 1.125rem;
            margin-left: .75rem;
            font-weight: 600;
            color: $neutral-gray-900;
        }
    }
    .tooltip-description{
        margin: 0;
        font-size: .75rem;
        color: $neutral-gray-600;
        margin-top: .5rem;
        line-height: 1.125rem;
    }
}

.table {
    margin-bottom: 0px;
}

//table 
.table-responsive{
    margin-top: 1.75rem;
    border: 1px solid $toggle-border-color;
    border-radius: 8px;
    overflow: scroll;
    padding-bottom: 5px;
}

@media screen and (max-width: 768px) {

    .table-responsive{
        min-width: 0px !important;
        overflow: hidden !important;
    }

    table.table{
        thead{
            tr{
                th{
                    padding-left: 0px !important;
                    padding-right: 0px !important;
                }   
            }
        }
    }

}


table.table{
    tr{
        border: none;
    }
    thead{
        border-radius: 10px;
        tr{
            border-radius: 10px;
            th{
                font-size: 12px;
                text-transform: uppercase;
                color: $neutral-gray-600;
                border-bottom: 2px solid $toggle-border-color;
                vertical-align: middle;
                white-space: nowrap;
            }   
        }
    }
    tbody{
        tr{
            td{
                font-size: 14px;
                vertical-align: middle;
                border: none;
                padding-top: 18px;
                padding-bottom: 18px;
                border-top: 2px solid $neutral-gray-50;
                background-color: $main-white;
            }
        }
        
    }
}

.user-kpi {
    // height: 230px !important;
}

.sort-container{
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
    cursor: pointer;
    img:last-child{
        margin-top: 4px;
    }
}

.margin-left {
    margin-left: 8px;
}

.table-head-icon{
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    img{
        margin: 0;
    }
    .icon-left{
        margin-right: 8px;
    }
    .icon-right{
        margin-left: 8px;
    }
}

.sort-container.asc{
    img:last-child{
        opacity: .4;
    }
}

.sort-container.desc{
    img:first-child{
        opacity: .4
    }
}

.search-uid{
    background-color: white;
    display: inline-flex;
    align-items: center;
    border: 1px solid $toggle-border-color;
    border-radius: 8px;
    padding: 0 4px;
    width: max-content;
    input{
        max-width: 6.125rem;
        text-transform: uppercase;
    }
}

.filter-dropdown{
    display: inline-block !important;
    button{
        background-color: transparent !important;
        border: none;
        padding: .185rem 0;
        &:hover{
            background-color: transparent !important;
            border-color: transparent !important;
        }
    }
    .dropdown-menu{
        text-transform: none !important;
        padding: .5rem .275rem;
        .dropdown-head{
            cursor: pointer;
            display: flex;
            justify-content: space-between;
        }
        .dropdown-item{
            padding-left: 2rem !important;
        }
    }
    
}

.dropdown-menu{
    max-height: 350px;
    overflow: scroll;
}

.period-filter-container{
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    padding: 0 1rem;
    box-shadow: $box-shadow;
    border-radius: .75rem;
    font-size: .75rem;
    background-color: $main-white;
    flex-direction: row;
    height: max-content;
    align-self: flex-end;
    margin-right: 1.5rem;
    .period-filter{
        padding: 0 .75rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
        font-weight: 600;
        cursor: pointer;
    }
    .period-filter.active{
        background-color: $secondary-yellow-500;
        border-radius: .75rem;
    }
}

@media screen and (max-width: 768px) {

    .period-filter-container{
        padding: 0 0 !important;
    }

}

.disposition-container{
    display: inline-flex;
    gap: .5rem;
    align-items: center;
    justify-content: space-around;
    .disposition-value.positive{
        font-weight: 600;
        font-size: .75rem;
        color: $primary-blue-600;
    }
    .disposition-value.negative{
        font-weight: 600;
        font-size: .75rem;
        color: $primary-blue-200;
    }
}

.disposition-value.positive{
    font-weight: 600;
    font-size: .75rem;
    color: $primary-blue-600;
}
.disposition-value.negative{
    font-weight: 600;
    font-size: .75rem;
    color: $primary-blue-200;
}

.activity-container{
    .progress{
        height: .625rem;
    }
    .progress-bar{
        background-color: $neutral-gray-500 !important;
    }
    .activity-value{
        font-weight: 600;
        font-size: .75rem;
        color: $neutral-gray-900;
    }
}

.scorecard-uid{
    font-weight: 600;
    color: $neutral-gray-500;
}

.bold-text{
    font-weight: 600;
    color: $neutral-gray-800;
}

.small-text{
    color: $neutral-gray-500 !important;
}

.bold-text{
    font-weight: 600 !important;
}

//switch

.app-toggle-container{
    
    input{
        display: none;
        
        &:checked + label{
            background: $success-green-500;
            &::after{
                transform: translateX(16px);
            }
        }
        &+ label{
            cursor: pointer;
            margin: 0;
            position: relative;
            display: flex;
            align-items: center;
            transition: background-color 300ms ease-in;
            width: 34px;
            height: 18px;
            border-radius: 9px;
            background: $neutral-gray-200;
        }
        &+ label::after{
            display: block;
            background: $main-white;
            transform: translateX(2px);
            z-index: 2;
            content: '';
            transition: 300ms ease-in transform;
            width: 1rem;
            height: 1rem;
            border-radius: .5rem;
            position: absolute;
            
        }
    }
    
    
}

//charts

.outcome-chart-container{
    max-height: 412px;
}


.circle-chart-container{
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
}

.overall-score{
    font-size: 16px !important;
    color: $neutral-gray-900;
    font-weight: bolder;
    font-family: 'Inter';
    text-align: center;
    margin: 0;
}

.overall-name{
    font-size: 10px;
    color: $neutral-gray-900;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;

}

.chart-section{
    background-color: white;
    border: 1px solid $neutral-gray-200;
    border-radius: 8px;
    padding: 8px 12px
}

.prior-title{
    font-size: 10px;
    margin: 0;
    text-transform: uppercase;
    color: $neutral-gray-400;

}

.rank-score{
    width: 80px;
    font-size: 12px !important;
    font-weight: 600;
    color: $neutral-gray-900;
    text-transform: uppercase;
    word-wrap: break-word;
    border-right: 2px solid #F8FAFC !important;
}

.bottom-rank-score{
    margin-top: 30px;
}

.top-rank-score{
    margin-bottom: 30px;
}

.rank-user{
    display: flex;
    justify-content: space-between;
    .uid{
        color:$neutral-gray-300;
        font-size: 10px;
    }
    .name{
        color: $neutral-gray-500;
        font-weight: 600;
        font-size: 10px;
        text-align: left;
        margin: 0 4px;
        margin-left: 10px;
        width: 60px;
    }

    .score{
        color: $neutral-gray-500;
        font-weight: 600;
        font-size: 10px;
        text-align: left;
        margin: 0 4px;
        margin-left: 0px;
    }
}
.text-large{
    font-size: 12px;
    color: $neutral-gray-900;
    font-weight: 600;
    margin-right: 4px;
}
.text-large-dull{
    color: $neutral-gray-400;
    font-size: 12px;
}
.text-small{
    color: $neutral-gray-400;
    font-size: 10px;
}

.outcome-row{
    font-size: 12px;
}

.change-summary{
    font-size: 60px;
    color: $primary-blue-300;
    .image-icon{
        width: 54px;
        height: 54px;
    }
}

.change{
    .prior-title{
        font-size: 10px;
        color: $neutral-gray-400;
        text-align: center;
    }
}

.action-name{
    font-size: 12px;
    color: $neutral-gray-900;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
}

.action-change{
    align-items: center;
    .image-icon{
        width: 24px;
        height: 24px;
    }
    .trend-change{
        font-size: 24px;
        font-weight: 600;
        color: $primary-blue-600;
    }
}

.adherence-prior-title{
    font-size: 10px;
    color: $neutral-gray-400;
    margin-bottom: 0;
}

.outcome-row-overall{
    td{
        background-color:#E8EDFB !important;
    }
}

.activity-table-container{
    max-width: 665px;
    margin: 0 auto;
}

.btn-activity{
    &.good{
        background-color: $primary-blue-200;
    }
    &.done{
        background-color: $neutral-gray-600;
        color: white;
    }
    &.excellent{
        background-color: #A7F3D0;
    }
    &.miss{
        background-color: #F1F5F9;
    }
    &.bad{
        background-color: #FDE68A;
    }
    &.okay{
        background-color: #E2E8F0;
    }
    &.terrible{
        background-color: #FECACA;
    }
}

.account-status{
    font-size: 16px;
    &.inactive{
        color: #F59E0B;
    }
    &.active{
        color: $success-green-500;
    }
    &.description{
        color: $neutral-gray-400;
    }
}

.network-label{
    font-weight: 600;
    color: $neutral-gray-900;
}

.network-value{
    color: $neutral-gray-600;
}