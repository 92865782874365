@import '../colors';

.member-name{
    margin: 0;
    color: $neutral-gray-900;
    font-weight: bold;
}

.member-email{
    color: $neutral-gray-500;
    font-size: 12px;
    margin: 0;
}

.level-container{
    display: flex;
    align-items: center;
}

.level-button{
    display: inline;
}

.btn-dropdown.dropdown-toggle{
    background-color: $main-white !important;
    border: 1px solid $toggle-border-color !important;
    color: $neutral-gray-900;
}
.show{
    .btn-dropdown.dropdown-toggle{
        color: $neutral-gray-600;
    }
}

.dropdown-item.active{
    background-color: $neutral-gray-50;
    color: $neutral-gray-900 !important;
    font-size: 14px;
}

.dropdown-menu{
    font-size: 14px;
}


.form-input{
    display: inline-flex;
    flex: 1 1 auto;
    border:none;
    outline: none;
    padding: .25rem .5rem;
    font-size: .875rem;
    width: auto;
}

