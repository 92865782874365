.no-padding-bottom {
    padding-bottom: 0px;
}

.no-padding-top {
    padding-top: 0px;
}

.no-padding-left {
    padding-left: 0px;
}

.no-padding-right {
    padding-right: 0px;
}

.no-margin-left {
    margin-left: 0px !important;
}

.no-margin-bottom {
    margin-bottom: 0px;
}
.no-margin-top {
    margin-top: 0px !important;
}

.light-border-top {
    border-top: 1px solid lightgray;
}

.light-border-bottom {
    border-bottom: 1px solid lightgray;
}

.normal-border-bottom {
    border-bottom: 1px solid gray !important;
}

.no-borders {
    border: none;
}


.profile-image {
    margin-bottom: 0px;
    margin-right: 10px;
    float: left;
}

.post-button {
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.upside-down {
    transform: rotate(180deg);
}

.float-right {
    float: right;
}

.post-image {
    margin-bottom: 0px;
    max-width: 100%;
    margin: auto;
}

.text-align-left {
    text-align: left;
}

.post-link-wrapper {
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    color: white;
}

.post-link-wrapper:hover {
    text-decoration: none;
    color: white;
}

.post-link-preview-image {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
}

.post-link-description {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: black;
}

.post-button-container {
    display: flex;
    justify-content: space-between;
    margin: auto;
}

.likes-bar {
    background-color: green;
    height: 5px;
    margin: auto;
}

.dislikes-bar {
    background-color: #dddddd;
    height: 5px;
    margin: auto;
}

.post-box {
    .panel {
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        margin-bottom: 30px;
        margin-top:10px;
        background-color: white;
    }

    textarea {
        border: none;
        resize: none;
    }

    .file-upload-container {
        padding-top: 15px;
        width: 100px;
        padding-bottom: 15px;
        float: left;
        cursor: pointer;
    }

    icon-icon {
        margin: auto;
    }

    .card {
        margin-top: 15px;
    }

    .col-auto {
        margin-top: 15px;
        margin-bottom: 15px;
        float: left;
        width: 50%;
        min-width: 100px;
        margin-left: 15px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .input-group-text {
        border-right: none !important;
        cursor: pointer;
    }
    
    .file-upload-container-preview {
        width: 100%;
        padding-right: 0px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .panel.panel-default {
        border: 1px solid #d4d4d4;
        -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.1);
    }

    .filepond--root .filepond--drop-label {
        min-height: 38px;
        border-radius: .25rem;
        border: 1px solid #ced4da;
        cursor: pointer;
    }

    .filepond--drop-label label {
        cursor: pointer;
    }

    .filepond--root {
        margin-bottom: 0px;
    }
    

    .panel-body {
        padding-top: 5px;
    }
    
    .btn-icon {
        color: #484848;
    }

    .pull-right {
        text-align: right;
        margin-bottom: 15px;
        margin-top: 15px;
        float: right;
    }
}




@media screen and (max-width: 768px) {

}

@media screen and (min-width: 768px) {
    
}