$primary-blue-500: #1D4ED8;
$primary-blue-600: #173EAD;

$primary-blue-200: #8EA7EC;
$primary-blue-50: #E8EDFB;
$error-red-300: #DC2626;
$secondary-yellow-500: #F7CE46;
$neutral-gray-500: #64748B;
$neutral-gray-900: #0F172A;
$neutral-gray-600: #475569;
$neutral-gray-800: #1E293B;
$neutral-gray-500: #64748B;
$neutral-gray-50: #F8FAFC;
$neutral-gray-400: #94A3B8;
$neutral-gray-300: #CBD5E1;
$neutral-gray-200: #E2E8F0;
$primary-blue-300: #6183E4;
$neutral-gray-100: #F1F5F9;

$success-green-500: #10B981;
$main-white: #FFFFFF;
$toggle-border-color: #CBD5E1;