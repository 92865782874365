$box-shadow: 0 2px 4px rgba(0, 0, 0, .05), 0 8px 16px rgba(0, 0, 0, .05);
$form-border: none;// 1px solid black;

html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  background-color: white;
  border-radius: $border-radius;
  border: $form-border;
  box-shadow: $box-shadow;
}

.error-message-container {
  margin: auto; 
  background-color: white;
  border-radius: .25rem;
  padding: 10px;
  text-align: center;
}

@media screen and (max-width: 768px) {
    .form-signin .form-control {
        font-size: 16px !important;
    }
}

.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  margin: 12px 0;
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 14px;
}
.form-signin .form-control:focus {
  z-index: 2;
}

*:focus {
  outline:none !important; 
  box-shadow: none !important;
}

.form-signin input {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
}

.flat-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.flat-bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.black-button {
  color: white !important;
  background-color: #112f82;
  border-color: transparent !important;
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
}
.form-signin .black-button {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: 14px;
}

.black-button:disabled {
  background-color: #112f82;
}

.form-signin .btn[type="go"]:active:focus {
  color: white;
  background-color: #444444;
}

.form-signin .btn[type="go"]:disabled:hover {
  color: white;
}