@import '~bootstrap/scss/bootstrap.scss';
@import './datetime.scss';
@import './signin.scss';
@import './post.scss';

$border-radius: 5px;
$header-margin: 10px;
$header-item-height: 40px;
$box-shadow: 0 2px 4px rgba(0, 0, 0, .03), 0 8px 16px rgba(0, 0, 0, .03);

@media print {
    body { background-color: #F8FAFC; font-family: "Inter" !important;}
    .hide-print-button {
      display: none;
    }

    .adherence-by-action {
        margin-top: 300px;
    }
}

// .OSX-body, .OSX-body *{
//     font-family: "Avenir Next" !important;
// }
.OSX-body{
    padding: 0 0 !important
}

.non-OSX-body, .non-OSX-body *{
    font-family: "Inter" !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.users-container {
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 2px;
        height: 4px;
        color: rgba(0, 0, 0, .1);
    }
    ::-webkit-scrollbar-thumb {
        height: 4px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .1);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
}

.rc-time-picker {
    padding-left: 0px !important;
    border: none !important;
}

.rc-time-picker-input:disabled {
    background-color: #e9ecef !important;
    color: #495057 !important;
    padding-left: 9px !important;
    border: 1px solid #ced4da !important;
}

.rc-time-picker-input::-webkit-input-placeholder {
    color: #495057 !important;
 }

 .form-control::-webkit-input-placeholder {
 }

 .manual-container {
     margin-top: 0;
     padding: 2rem 2rem;
 }
 
 @media screen and (max-width: 768px) {
    .manual-container {
        padding: 1rem 1rem;
    }
}
  

 .activated-checkmark {
     max-width: 25px;
     max-height: 25px;
     filter: invert(74%) sepia(27%) saturate(423%) hue-rotate(74deg) brightness(96%) contrast(93%);
 }

.input-group {
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}

.users-list {
    flex-wrap: nowrap !important;

    p {
        font-size: 12px;
        height: 30px;
    }
    .col-2 {
        max-width: 150px;
    }
    .col-3 {
        max-width: 200px;
    }
}

.remove-user-button {
    height: 15px;
    width: 100px;
    font-size: 10px;
    padding-top: 0px;
}

.level-button {
    height: 15px;
    width: 25px;
    font-size: 15px;
    text-align: center;
    padding: 0px 0px 0px 0px;
    line-height: 5px;
    margin-top: 1px;
}

.level-button-plus {
    margin-left: 5px;
}


.level-button-minus {
    margin-right: 5px;
}

.row {
    display: flex;
    flex-wrap: wrap;
}
.row > [class*='col-'] {
    display: flex;
    flex-direction: column;
}

.header {
    .btn {
        color: white;
        background-color: black;
    }
    .btn-highlighted, .btn:focus, .btn:active, .btn:hover {
        background-color: white !important;
        color: black !important;
    }
}

.stats-filters {

    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;

    option {
        text-transform: capitalize;
    }

}

.stats-filters-item {
    margin-right: 10px;
    margin-bottom: 10px;
}

select {
    padding-right: 10px;
}

.uppercase-option {
    text-transform: uppercase;
}

.column-scroll-container {
    overflow: auto;
    white-space: nowrap;

    p {
        text-align: left;
    }
    
    .col-1 {
        display: inline-block;
    }

    .col-2 {
        display: inline-block;
    }

    .col-3 {
        display: inline-block;
    }
}


.no-padding-bottom {
    padding-bottom: 0px;
}

@media screen and (max-width: 768px) {
    body { background-color: #F8FAFC; font-family: "Inter" !important; margin-top: 0px !important; padding-top: 0px !important;}

    .header {
        padding-top: .5rem;
        padding-bottom: .5rem;
        background-color: black;
        display: inline-block;
    }

    .horizontalContainer {
        height: 100%;
        margin: 0 auto;
        max-width: 600px;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
    }

    .logo {
        height: 30px;
        border-radius: $border-radius;
        margin-left: 9px;
        margin-top: 5px;
    }

    .logoContainer {
        float: left;
        background-color: white;
        width: $header-item-height;
        height: $header-item-height;
        border-radius: 5px;
        margin-right: $header-margin;
    }

    .search-bar {
        float: left;
        width: 100%;
        max-width: 400px;
        height: $header-item-height;
        margin-right: 10px;
        .form-control {
            height: 100%;
        }
    }f

    .login-logo {
        margin: auto;
    }

    .login-right-column {
        min-width: 330px;
        padding-top: 50px;
        flex-grow: 1 !important;
    }
    
    @media screen and (max-width: 768px) {
        .login-right-column {
            padding-top: 0px;
        }    
    }
      
    .login-left-column {
        text-align: left;
    }

    .login-left-column h1 {
        font-size: 20px;
        min-width: 330px;
    }
    table.table{
        td{
            padding: .325em;
            font-size: 12px;
        }
    }
  }

  @media screen and (min-width: 768px) {
    body { background-color: #F8FAFC; font-family: "Inter" !important;}

    .header {
        padding-top: .5rem;
        padding-bottom: .5rem;
        background-color: black;
        display: inline-block;
    }

    .horizontalContainer {
        margin: 0 auto;
        max-width: 600px;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .logo {
        height: $header-item-height - 10;
    }

    .logoContainer {
        float: left;
        background-color: black;
        width: $header-item-height;
        height: $header-item-height;
        border-radius: 5px;
        margin-right: $header-margin;
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .search-bar {
        float: left;
        width: 100%;
        max-width: 400px;
        height: $header-item-height;
        .form-control {
            height: 100%;
        }
    }

    .login-logo {
        margin: auto;
    }

    .login-right-column {
        min-width: 330px;
        flex-grow: 0 !important;
    }
      
    .login-left-column {
        text-align: left;
        flex-grow: 1;
    }
    .calendar-card{
        padding: .525rem;
    }
  }

.timers-container {
    display: flex;
    justify-content: space-around;
    font-family: sans-serif;
    text-align: center;
  }
  
  .btn-group {
      padding-bottom: 10px;
  }

  .comment-header {
      font-size: 1.0rem;
      text-align: left;
      line-height: 1.0rem;
  }

  .comment-body {
    margin-left: 15px;
    text-align: left;
    line-height: 1.0rem;
    margin-bottom: 0;
    }  
    
    .live-chat-comment-body {
        text-align: left;
        line-height: 1.0rem;
    } 

    .comment-reply-button {
        margin-left: 15px;
        border: none !important;
    }
    
    .comment-container {
        margin-top: 15px;
        width: 100%;
        p {
            word-break: break-all;
        }
    }

    .no-border-bottom {
        border-bottom: none !important;
    }

    .comment-meta {
        margin-bottom: 5px;
        margin-left: 15px;
        text-align: left;
    }

    .comment-meta a {
        font-size: 12px;
        color: grey;
        font-weight: bolder;
        margin-right: 5px;
    }

    .content-scroll-wrap {
        position: relative;
    }

    .content-scroll {
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;
        overflow: auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    .img-circle {
        border-radius: 50%;
    }

    .half-border-circle {
        width:38px; height:38px;
        background:#666;
        border:1px solid black;
        border-radius:50%;
        border-bottom-color:#fff;
        border-right-color:#fff;
    }

    .light-border-bottom {
        border-bottom: 1px solid #eeeeee;
    }

    .light-border {
        border: 1px solid #eeeeee;
    }

    .brigade-title {
        text-align: left;
        font-size: 30px;
    }

    .brigade-image {
        width: 100%;
        height: 300px;
        object-fit: cover;
        min-width: 100%;
    }

    .brigade-preview-image {

        width: 100%;
        height: 150px;
        min-width: 100%;
        object-fit: cover;
    }

    .card {
        box-shadow: $box-shadow;
        border-width: 0;
        border-radius: 10px;
    }

    .card-text {
        text-align: left;
    }

    .live-chats-container {
        display: flex;
        flex-direction: column-reverse;
        overflow: auto;
    }

    .rdt {
        display: block !important;
    }

    /*.filepond--wrapper {
        display: inline-block !important;
    }

    .filepond--root {
        margin-bottom: 0px !important;
    }*/

    .inline-content{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    

    img:-moz-loading {
        visibility: hidden;
    }

    img {
        display: none;
    }
    
    
    img[src] {
       display: block;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #aab7c4 !important;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #aab7c4 !important;
    }
      
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #aab7c4 !important;
    }

    table{
        td.calendar-cell-button{
            cursor: pointer;
            margin-left: 5;
            position: relative;
            border: 2px solid #e4e4e4;
            color: white;
            &::before{
                --scale: 0;
                --arrow-size: 5px;
                --tooltip-color: #333;
                position: absolute;
                top: -.25rem;
                left: 50%;
                transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
                transition: 150ms transform;
                transform-origin: bottom center;
                --translate-y: calc(-100% - var(--arrow-size));
                content: attr(data-tooltip);
                color: white;
                padding: .2rem;
                border-radius: .2rem;
                text-align: center;
                width: max-content;
                max-width: 150px;
                background: var(--tooltip-color);
                opacity: 1 !important;
                font-size: 10px;
            }
            &::after{
                --scale: 0;
                --arrow-size: 10px;
                --tooltip-color: #333;
                position: absolute;
                top: -.25rem;
                left: 50%;
                transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
                transition: 150ms transform;
                transform-origin: bottom center;
            }
            &:hover {
                &::before {
                    --scale: 1;
                }
                &::after{
                    --scale: 1;
                    --translate-y: calc(-1 * var(--arrow-size));
                    content: '';
                    border: var(--arrow-size) solid transparent;
                    border-top-color: var(--tooltip-color);
                    transform-origin: top center;
                    opacity: 1;
                }
            }
            
        }
        
    }
    table.table{
        td{
            border: 4px solid white;
            padding: .525em;
        }
    }
    table.table{
        thead{
            th{
                border: 0;
            }
        }
    }

    .metric-block{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 3px 0;
        
    }

    .overview-modal-subheading{
        font-size: 16px;
        margin-bottom: 5px;
    }
    .calendar-title{
        margin-bottom: 0;
        font-family: "Inter" !important;
        font-weight: bold;
    }

    .calendar-key-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: white;
        border-radius: 0.2rem;
        height: 37px;
        max-width: 314px;
    }

    .calendar-key-color{
        display: block;
        width: 32px;
        height: 20px;
        margin-right: 4px;
    }

    .calendar-key-label{
        font-size: 12px;
        font-weight: bold;
        margin: 0 10px;
    }

    table.table.calendar tbody tr td {
        font-size: 10px;
        text-align: center;
        border: 1px solid white;
    }
    table.table.calendar thead tr th {
        font-size: 12px;
        text-align: center;
        border-bottom-color: transparent;
        color: #0F172A;
        width: 45px;
        font-weight: normal;
    }

    .calendar-title{
        text-align: center;
        text-transform: uppercase;
    }

