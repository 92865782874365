@import '../colors';
@import '../sizes';


.scroll-overflow {
    overflow: scroll;
}

.protocol-card-container{
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.089);
    border-radius: 8px;
    margin-bottom: 24px;
    .top-section, .bottom-section{
        display:  flex;
        flex-direction: row;
        padding: .75rem 1rem;
    }
    .top-section{
        cursor: pointer;
        justify-content: space-between;
        background: $main-white;
        border-top-left-radius: .5rem;
        border-top-right-radius: .5rem;
        &.active{
            background: $primary-blue-50;
        }
        .protocol-head{
            display: flex;
            align-items: center;
            .protocol-name{
                font-size: $large-text;
                margin: 0;
                font-weight: 600;
                font-family: "Inter";
            }
            .protocol-date{
                margin: 0;
                font-size: $normal-text;
                color: $neutral-gray-500;

            }
            .circle-icon{
                width: 3rem;
                height: 3rem;
                border-radius: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $primary-blue-50;
                margin-right: .75rem;
                &.active{
                    background-color: $main-white
                }
            }
           
        }
        .dropdown{
            .dropdown-toggle.menu-button{
                background: transparent;
                border: none;
                &::after{
                    display: none;
                    outline: none;
                }
            }
            .dropdown-menu{
                .dropdown-item{
                    padding: .75rem .875rem;
                }
            }
        }
    }
    .bottom-section{
        align-items: center;
        justify-content: flex-end;
        overflow-x: scroll;
        .btn{
            margin-left: .75rem;
        }
        .privacy{
            display: flex;
            flex-direction: row;
            align-items: center;
            span{
                font-size: $normal-text;
                color: $neutral-gray-500;
            }
        }

        @media screen and (max-width: 1000px) {
            .privacy{
                display: none !important
            }
        }
    }

    .editing-text-container {
        padding-left: 1rem;
        padding-bottom: 5px;
    }
    .editing-text {
        font-size: $normal-text;
        color: $neutral-gray-400;
        margin-bottom: 10px;
    }
}
.level-btn{
    font-size: 12px;
    background-color: $neutral-gray-50;
    padding: 6px 12px;
    border-radius: 5px;
    margin: 0 2px;
    cursor: pointer;
}
.level-btn-current{
    width: max-content;
    min-width: 57px;
    text-align: center;
    height: 29px;
    border-radius: 5px;
    padding: 6px 8px;
    font-size: 12px;
    margin: 0 4px;
    background-color: $neutral-gray-50;

}
.level-btn-current.active{
    background-color: $primary-blue-50;
    color: $primary-blue-500;
}
.form-control.option{
    border: none;
    outline: none;
    font-size: 12px;
    display: flex;
    justify-content: center;
    border-radius: 0;
    cursor: pointer;
}

.form-control.option.active{
    background-color: $primary-blue-50;
    color: $neutral-gray-900;
    font-weight: bold;
    font-size: 12px;
}

.qualities-list{
    margin-top: .5rem;
    .quality-header{
        font-size: .75rem;
        font-weight: 600;
        color: $neutral-gray-500;
        &.light{
        color: $neutral-gray-300;

        }
    }
}

.metric-list{
    margin-top: .5rem;
    .quality-header{
        font-size: .75rem;
        font-weight: 600;
        color: $neutral-gray-500;
        &.light{
        color: $neutral-gray-300;

        }
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: .75rem;
    padding-right: .75rem;
    
}
.quality-header{
    font-size: .75rem;
    font-weight: 600;
    margin: 0;
    color: $neutral-gray-500;
    &.light{
    color: $neutral-gray-300;

    }
}

.metric-row{
    display: flex;
    align-items: center;
    padding-left: 1rem;
    margin: .75rem 0;
    .highlight{
        background-color: $neutral-gray-50;
        padding: .5rem .75rem;
        font-size: .75rem;
        width: 100%;
        border-radius: .25rem;
        margin-right: 15px;
    }

    button:first-of-type{
        margin-left: auto;
        
    }

    .button-icon {
        width: 18px;
        height: 18px;
    }

    .smaller-button-icon {
        width: 16px;
        height: 16px;
    }
}

.full-width {
    width: 100%;
}

.quality-tile{
    height: 3.25rem;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    justify-content: space-between;
    padding: 6px 10px;
    margin-bottom: .625rem;;
    box-shadow: 0 1px 0 #e2e8f0;
    .quality-type{
        font-size: 12px;
        color: $neutral-gray-400;
    }
    .quality-name{
        font-size: 14px;
        color: $neutral-gray-900;
        display: block;
        margin: 0;
        width: 100%;
    }
    .quality-icon{
        align-self: flex-end;
    }
    .quality-bar{
        margin: 0;
        align-self: flex-start;
        font-size: .75rem;
        background-color: $neutral-gray-100;
        color: $neutral-gray-400;
        border-radius: .25rem;
        padding-left: 2.5px;
        padding-right: 2.5px;
    }
}

.action-tile{
    display: flex;
    align-items: center;
    padding: 0 .75rem;
    height: 1.625rem;
    font-size: .75rem;
    margin-bottom: 12px;
    
    .details{
        display: flex;
        box-shadow: 0 1px 0 #e2e8f0;
        border-radius: .25rem;
        width: 200px;
        padding-bottom: 5px;
        padding-top: 5px;
    }
    .context{
        padding: .25rem .5rem;
        background-color: $neutral-gray-100;
        border-radius: .25rem;
        margin-left: 5px;
    }
    .name{
        padding: .25rem .5rem;
    }
    .image-icon{
        width: 18px;
        height: 18px;
    }

    .left {
        margin-right: 10px;
    }
}

.modal-container.modal-md{
    max-height: 625px;
}

.modal-bd-short {
    max-height: 300px;
    overflow-y: scroll;
    padding-right: 1px;
    padding-left: 1px;
    padding-bottom: 1px;
}



